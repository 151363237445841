import { KeyLabel } from 'components/KeyLabel';

export const MIN_PASSWORD_LENGTH =
  process.env.NEXT_PUBLIC_MIN_PASSWORD_LENGTH || 8;
export const MIN_PITCH_LENGTH = 100;
export const NEXT_AUTH_DEBUG = process.env.NEXT_AUTH_DEBUG || false;
export const drawerWidth = 210;
export const appBarHeight = 60;
export const rightDrawerWidth = 360;
export const profileOptions = ['SEARCHER', 'OWNER'];
export const initialConvoState = ['NEW', 'ACCEPTED'];
export const REJIGG_SUPPORT_PERSON_ID = 84424;

export const REJIGG_SUPPORT_CALENDLY_URL =
  'https://calendly.com/rejigg-team/support';

export const REJIGG_SEARCHER_FAQ_URL =
  'https://rejigg.notion.site/Buyer-FAQ-120a071e4b084963825f5e07a3c9c60a';

export const REJIGG_OWNER_FAQ_URL =
  'https://rejigg.notion.site/Owner-FAQ-8f917fc7ba1d41b5ac4a43bc6efdbeb2';

export const enterKeyBehaviorOptions = {
  ENTER_ADDS_NEW_LINE: (
    <>
      <KeyLabel text="Enter" /> for new line. <KeyLabel text="Ctrl/Command" />+
      <KeyLabel text="Enter" /> to send
    </>
  ),
  ENTER_SENDS_MESSAGE: (
    <>
      <KeyLabel text="Enter" /> sends the message. <KeyLabel text="Shift" />+
      <KeyLabel text="Enter" /> to add a new line
    </>
  ),
};
export class USDollar {
  static format(number) {
    if (isNaN(number) || number === undefined || number === null) {
      return '-';
    } else {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(number);
    }
  }

  static denseFormat(number) {
    if (isNaN(number) || number === undefined || number === null) {
      return '-';
    } else {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        notation: 'compact',
        maximumFractionDigits: 1,
        minimumFractionDigits: 0,
      }).format(number);
    }
  }
}

export const acmeImages = [
  {
    id: 1,
    name: '',
    thumbnails: {
      sm: 'images/acme/acme-logo.jpg__420x420_q85_crop_subsampling-2_upscale.jpg',
      md: 'images/acme/acme-logo.jpg__420x420_q85_crop_subsampling-2_upscale.jpg',
      lg: 'images/acme/acme-logo.jpg__420x420_q85_crop_subsampling-2_upscale.jpg',
      xl: 'images/acme/acme-logo.jpg__800x0_q85_crop_subsampling-2_upscale.jpg',
    },
  },
  {
    id: 2,
    name: '',
    thumbnails: {
      sm: 'images/acme/acme-image2.jpg__420x420_q85_crop_subsampling-2_upscale.jpg',
      md: 'images/acme/acme-image2.jpg__420x420_q85_crop_subsampling-2_upscale.jpg',
      lg: 'images/acme/acme-image2.jpg__420x420_q85_crop_subsampling-2_upscale.jpg',
      xl: 'images/acme/acme-image2.jpg__800x0_q85_crop_subsampling-2_upscale.jpg',
    },
  },
  {
    id: 3,
    name: '',
    thumbnails: {
      sm: 'images/acme/acme-image.jpg__420x420_q85_crop_subsampling-2_upscale.jpg',
      md: 'images/acme/acme-image.jpg__420x420_q85_crop_subsampling-2_upscale.jpg',
      lg: 'images/acme/acme-image.jpg__420x420_q85_crop_subsampling-2_upscale.jpg',
      xl: 'images/acme/acme-image.jpg__800x0_q85_crop_subsampling-2_upscale.jpg',
    },
  },
  {
    id: 4,
    name: '',
    thumbnails: {
      sm: 'images/acme/acme-planned.jpg__420x420_q85_crop_subsampling-2_upscale.jpg',
      md: 'images/acme/acme-planned.jpg__420x420_q85_crop_subsampling-2_upscale.jpg',
      lg: 'images/acme/acme-planned.jpg__420x420_q85_crop_subsampling-2_upscale.jpg',
      xl: 'images/acme/acme-planned.jpg__800x0_q85_crop_subsampling-2_upscale.jpg',
    },
  },
];
export const acmeLogo = acmeImages[0];

export const usStates = [
  { code: 'AL', name: 'Alabama' },
  { code: 'AK', name: 'Alaska' },
  { code: 'AZ', name: 'Arizona' },
  { code: 'AR', name: 'Arkansas' },
  { code: 'CA', name: 'California' },
  { code: 'CO', name: 'Colorado' },
  { code: 'CT', name: 'Connecticut' },
  { code: 'DE', name: 'Delaware' },
  { code: 'FL', name: 'Florida' },
  { code: 'GA', name: 'Georgia' },
  { code: 'HI', name: 'Hawaii' },
  { code: 'ID', name: 'Idaho' },
  { code: 'IL', name: 'Illinois' },
  { code: 'IN', name: 'Indiana' },
  { code: 'IA', name: 'Iowa' },
  { code: 'KS', name: 'Kansas' },
  { code: 'KY', name: 'Kentucky' },
  { code: 'LA', name: 'Louisiana' },
  { code: 'ME', name: 'Maine' },
  { code: 'MD', name: 'Maryland' },
  { code: 'MA', name: 'Massachusetts' },
  { code: 'MI', name: 'Michigan' },
  { code: 'MN', name: 'Minnesota' },
  { code: 'MS', name: 'Mississippi' },
  { code: 'MO', name: 'Missouri' },
  { code: 'MT', name: 'Montana' },
  { code: 'NE', name: 'Nebraska' },
  { code: 'NV', name: 'Nevada' },
  { code: 'NH', name: 'New Hampshire' },
  { code: 'NJ', name: 'New Jersey' },
  { code: 'NM', name: 'New Mexico' },
  { code: 'NY', name: 'New York' },
  { code: 'NC', name: 'North Carolina' },
  { code: 'ND', name: 'North Dakota' },
  { code: 'OH', name: 'Ohio' },
  { code: 'OK', name: 'Oklahoma' },
  { code: 'OR', name: 'Oregon' },
  { code: 'PA', name: 'Pennsylvania' },
  { code: 'RI', name: 'Rhode Island' },
  { code: 'SC', name: 'South Carolina' },
  { code: 'SD', name: 'South Dakota' },
  { code: 'TN', name: 'Tennessee' },
  { code: 'TX', name: 'Texas' },
  { code: 'UT', name: 'Utah' },
  { code: 'VT', name: 'Vermont' },
  { code: 'VA', name: 'Virginia' },
  { code: 'WA', name: 'Washington' },
  { code: 'WV', name: 'West Virginia' },
  { code: 'WI', name: 'Wisconsin' },
  { code: 'WY', name: 'Wyoming' },
  { code: 'ZZ', name: 'Other' },
];

export const getUSStateName = (code) => {
  if (!code) return '';

  const state = usStates.find((s) => s.code === code);
  return state ? state.name : code;
};

export const topTimezones = [
  { timezone: 'America/Los_Angeles', name: 'Pacific Time' },
  { timezone: 'America/Denver', name: 'Mountain Time' },
  { timezone: 'America/Chicago', name: 'Central Time' },
  { timezone: 'America/New_York', name: 'Eastern Time' },
];

export const compactTimezones = [
  { timezone: 'Africa/Algiers', name: 'West Central Africa' },
  { timezone: 'Africa/Cairo', name: 'Cairo' },
  { timezone: 'Africa/Casablanca', name: 'Casablanca, Monrovia' },
  { timezone: 'Africa/Harare', name: 'Harare, Pretoria' },
  { timezone: 'Africa/Nairobi', name: 'Nairobi' },
  { timezone: 'America/Anchorage', name: 'Alaska Time' },
  {
    timezone: 'America/Argentina/Buenos_Aires',
    name: 'Buenos Aires, Georgetown',
  },
  { timezone: 'America/Boise', name: 'Mountain Time' },
  { timezone: 'America/Belize', name: 'Central America' },
  { timezone: 'America/Bogota', name: 'Bogota, Lima, Quito' },
  { timezone: 'America/Caracas', name: 'Caracas, La Paz' },
  // { timezone: "America/Chicago", name: "Central Time" },
  { timezone: 'America/Chihuahua', name: 'Chihuahua, La Paz, Mazatlan' },
  { timezone: 'America/Dawson', name: 'Dawson, Yukon' },
  // { timezone: "America/Denver", name: "Mountain Time" },
  { timezone: 'America/Detroit', name: 'Eastern Time' },
  { timezone: 'America/Godthab', name: 'Greenland' },
  { timezone: 'America/Juneau', name: 'Alaska' },
  // { timezone: "America/Los_Angeles", name: "Pacific Time" },
  {
    timezone: 'America/Mexico_City',
    name: 'Guadalajara, Mexico City, Monterrey',
  },
  { timezone: 'America/Montevideo', name: 'Montevideo' },
  // { timezone: "America/New_York", name: "Eastern Time" },
  { timezone: 'America/Phoenix', name: 'Arizona' },
  { timezone: 'America/Regina', name: 'Saskatchewan' },
  { timezone: 'America/Santiago', name: 'Santiago' },
  { timezone: 'America/Sao_Paulo', name: 'Brasilia' },
  { timezone: 'America/St_Johns', name: 'Newfoundland and Labrador' },
  { timezone: 'America/Tijuana', name: 'Tijuana' },
  { timezone: 'Asia/Almaty', name: 'Almaty, Novosibirsk' },
  { timezone: 'Asia/Baghdad', name: 'Baghdad' },
  { timezone: 'Asia/Baku', name: 'Baku, Tbilisi, Yerevan' },
  { timezone: 'Asia/Bangkok', name: 'Bangkok, Hanoi, Jakarta' },
  { timezone: 'Asia/Colombo', name: 'Sri Jayawardenepura' },
  { timezone: 'Asia/Dhaka', name: 'Astana, Dhaka' },
  { timezone: 'Asia/Dubai', name: 'Abu Dhabi, Muscat' },
  {
    timezone: 'Asia/Helsinki',
    name: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  },
  { timezone: 'Asia/Irkutsk', name: 'Irkutsk, Ulaanbaatar' },
  { timezone: 'Asia/Jerusalem', name: 'Jerusalem' },
  { timezone: 'Asia/Kabul', name: 'Kabul' },
  { timezone: 'Asia/Kamchatka', name: 'Kamchatka, Marshall Islands' },
  { timezone: 'Asia/Karachi', name: 'Islamabad, Karachi, Tashkent' },
  { timezone: 'Asia/Kathmandu', name: 'Kathmandu' },
  { timezone: 'Asia/Kolkata', name: 'Chennai, Kolkata, Mumbai, New Delhi' },
  { timezone: 'Asia/Krasnoyarsk', name: 'Krasnoyarsk' },
  { timezone: 'Asia/Kuala_Lumpur', name: 'Kuala Lumpur, Singapore' },
  { timezone: 'Asia/Magadan', name: 'Magadan, Solomon Islands, New Caledonia' },
  {
    timezone: 'Asia/Moscow',
    name: 'Istanbul, Minsk, Moscow, St. Petersburg, Volgograd',
  },
  { timezone: 'Asia/Rangoon', name: 'Yangon Rangoon' },
  { timezone: 'Asia/Seoul', name: 'Seoul' },
  {
    timezone: 'Asia/Shanghai',
    name: 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  },
  { timezone: 'Asia/Taipei', name: 'Taipei' },
  { timezone: 'Asia/Tehran', name: 'Tehran' },
  { timezone: 'Asia/Tokyo', name: 'Osaka, Sapporo, Tokyo' },
  { timezone: 'Asia/Yakutsk', name: 'Yakutsk' },
  { timezone: 'Asia/Yekaterinburg', name: 'Ekaterinburg' },
  { timezone: 'Atlantic/Azores', name: 'Azores' },
  { timezone: 'Atlantic/Cape_Verde', name: 'Cape Verde Islands' },
  { timezone: 'Atlantic/Canary', name: 'Canary Islands' },
  { timezone: 'Atlantic/Reykjavik', name: 'Greenwich Mean Time : Reykjavik' },
  { timezone: 'Australia/Adelaide', name: 'Adelaide' },
  { timezone: 'Australia/Brisbane', name: 'Brisbane' },
  { timezone: 'Australia/Darwin', name: 'Darwin' },
  { timezone: 'Australia/Hobart', name: 'Hobart' },
  { timezone: 'Australia/Perth', name: 'Perth' },
  { timezone: 'Australia/Sydney', name: 'Canberra, Melbourne, Sydney' },
  {
    timezone: 'Europe/Amsterdam',
    name: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  { timezone: 'Europe/Athens', name: 'Athens' },
  {
    timezone: 'Europe/Belgrade',
    name: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  },
  {
    timezone: 'Europe/Berlin',
    name: 'Central European Time - Berlin, Madrid, Paris',
  },
  { timezone: 'Europe/Brussels', name: 'Brussels, Copenhagen, Madrid, Paris' },
  { timezone: 'Europe/Bucharest', name: 'Bucharest' },
  { timezone: 'Europe/Dublin', name: 'Dublin' },
  {
    timezone: 'Europe/Helsinki',
    name: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  },
  { timezone: 'Europe/Istanbul', name: 'Istanbul' },
  { timezone: 'Europe/Lisbon', name: 'Lisbon' },
  { timezone: 'Europe/London', name: 'Greenwich Mean Time - London' },
  {
    timezone: 'Europe/Moscow',
    name: 'Istanbul, Minsk, Moscow, St. Petersburg, Volgograd',
  },
  {
    timezone: 'Europe/Paris',
    name: 'Central European Time - Berlin, Madrid, Paris',
  },
  {
    timezone: 'Europe/Rome',
    name: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  { timezone: 'Europe/Sarajevo', name: 'Sarajevo, Skopje, Warsaw, Zagreb' },
  {
    timezone: 'Europe/Sofia',
    name: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  },
  {
    timezone: 'Europe/Stockholm',
    name: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  {
    timezone: 'Europe/Vienna',
    name: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  { timezone: 'Europe/Warsaw', name: 'Sarajevo, Skopje, Warsaw, Zagreb' },
  { timezone: 'Europe/Zagreb', name: 'Sarajevo, Skopje, Warsaw, Zagreb' },
  { timezone: 'Pacific/Auckland', name: 'New Zealand Standard Time' },
  { timezone: 'Pacific/Chatham', name: 'Chatham Islands Time' },
  { timezone: 'Pacific/Fiji', name: 'Fiji Time' },
  { timezone: 'Pacific/Guam', name: 'Guam, Port Moresby' },
  { timezone: 'Pacific/Honolulu', name: 'Hawaii' },
  { timezone: 'Pacific/Kiritimati', name: 'Line Islands Time' },
  { timezone: 'Pacific/Midway', name: 'Midway Island, Samoa' },
  { timezone: 'Pacific/Tongatapu', name: "Nuku'alofa" },
  { timezone: 'UTC', name: 'UTC' },
];

export const timezones = [
  ...topTimezones,
  { divider: true },
  ...compactTimezones,
];

export const SUBSCRIBE_PAGE_DATA = {
  redactedData: {
    owner: {
      id: -1,
      biography:
        'When ?? founded ?? at age 25, his vision was to run a business supplying the highest quality anvils to blacksmiths. The product line expansion was driven by his own personal interest in creative pest control solutions for his family ranch. His business expanded greatly in the 2000s when ?? switched from a mail order catalog to a web-based ordering system and since then he has had an experienced management team in place that he trusts. He is now looking to sell the business since he is planning to retire to spend more time with his grandkids.',
      first_name: '??',
      last_name: '??',
    },
    business_id: 'REDACTED',
    business: {
      redacted_name: 'Southwestern supplier of industrial manufactured goods.',
      id: 'REDACTED',
      name: 'Manufacturing Business',
      one_sentence_description:
        'Southwestern supplier of industrial manufactured goods.',
      revenue_type: 'Unknown',
      why_sell_description: 'Owner is retiring',
      what_owner_wants_description: '',
      other_information:
        '?? started as an anvil business but has grown its product line into other areas such as rockets, explosives, lassos, rubber products and pest control devices. By expanding the line of products in recent years ?? has grown at a 20% CAGR in the last 5 years to a current annual recurring revenue of $10m. The company employs 20 people and owns two processing plants in the southwestern US where all manufacturing takes place.',
      clients: '80% of revenue comes from a single client',
      price_low: '10000000',
      price_high: '12000000',
      revenue: '10000000',
      ebitda: '5000000',
      website_url: 'REDACTED',
      address_city: 'REDACTED',
      employee_count: 20,
      industry: 'Manufacturing',
      tags: [],
      is_redacted: true,
      finances: [
        {
          year: 2022,
          revenue: '10000000',
          ebitda: '5000000',
        },
        {
          year: 2021,
          revenue: '8000000',
          ebitda: '4000000',
        },
      ],
    },
    created_at: new Date().toISOString(),
    first_viewed_at: new Date().toISOString(),
  },

  fullData: {
    business_id: "example",
    owner_id: -1,
    owner: {
      id: -1,
      biography:
        'When Chuck founded ACME at age 25, his vision was to run a business supplying the highest quality anvils to blacksmiths. The product line expansion was driven by his own personal interest in creative pest control solutions for his family ranch. His business expanded greatly in the 2000s when ACME switched from a mail order catalog to a web-based ordering system and since then he has had an experienced management team in place that he trusts. He is now looking to sell the business since he is planning to retire to spend more time with his grandkids.',
      first_name: 'Chuck',
      last_name: 'Jones',
      linkedin_url: 'https://linkedin.com/acmeio',
      website_url: 'https://acme.io',
      main_location: 'United States',
    },
    business: {
      id: "example",
      is_redacted: false,
      name: 'ACME Inc.',
      redacted_name: 'Southwestern supplier of industrial manufactured goods.',
      one_sentence_description:
        'Southwestern supplier of industrial manufactured goods.',
      revenue_type: 'Unknown',
      why_sell_description: 'Owner is retiring.',
      what_owner_wants_description: '',
      other_information:
        'ACME started as an anvil business but has grown its product line into other areas such as rockets, explosives, lassos, rubber products and pest control devices. By expanding the line of products in recent years ACME has grown at a 20% CAGR in the last 5 years to a current annual recurring revenue of $10m. The company employs 20 people and owns two processing plants in the southwestern US where all manufacturing takes place.',
      clients: '80% of revenue comes from a single client',
      revenue: '10000000',
      ebitda: '5000000',
      price_low: '10000000',
      price_high: '12000000',
      website_url: 'https://ACME.com',
      address_city: 'Phoenix, AZ',
      employee_count: 20,
      industry: 'Manufacturing',
      tags: ['remote work available'],
      finances: [
        {
          year: 2022,
          revenue: '10000000',
          ebitda: '5000000',
        },
        {
          year: 2021,
          revenue: '8000000',
          ebitda: '4000000',
        },
      ],
    },
    created_at: new Date().toISOString(),
    first_viewed_at: new Date().toISOString(),
  },
};

export const CALL_CANCEL_CONFIRM_MESSAGE = {
  title: 'Are you sure you want to cancel this call?',
  content: "This action can't be undone.",
  confirmationText: 'Yes, cancel it!',
  confirmationButtonProps: { variant: 'contained', color: 'error' },
  dialogProps: { maxWidth: 'xs' },
  dialogActionsProps: {
    sx: {
      justifyContent: 'space-between',
    },
  },
};

export const menuOrigins = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};